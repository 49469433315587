:root {
  --fog: #cfc9bc;
  --bright-fog: #f3f1ee;
  --dark-fog: #7c7e74;

  --kelp: #444638;
  --bright-kelp: #7c7e74;
  --dark-kelp: #11190c;

  --magic: #e6ff00;
  --bright-magic: #f7ffb0;
  --dark-magic: #cce201;

  /* for overriding the default color of amlify */
  --amplify-background-color: var(--bright-fog);
  --amplify-primary-color: var(--kelp);
  --amplify-primary-tint: var(--bright-kelp);
  --amplify-secondary-color: var(--dark-kelp);
  --amplify-primary-shade: var(--dark-magic);
  -webkit-tap-highlight-color: transparent;
}

.ant-layout-has-sider {
  margin-top: 75px;
}

.logo {
  width: 140px;
}

.ant-layout-header {
  height: 75px;
  padding: 0 18px !important;
  line-height: 75px;
}

.right-group {
  float: right;
}

.right-group-item {
  margin: 0 10px;
}

.pre-dark {
  padding: 20px;
  color: #83d800;
  background-color: #222;
  white-space: pre;
  text-shadow: 0 1px 0 #000;
  border-radius: 5px;
  border-bottom: 1px solid #555;
  box-shadow: 1px 1px 5px -1px rgb(161, 161, 161);
  font: 16px/24px "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;

  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.pre-light {
  padding: 20px;
  background-color: #e6e6e6;
  white-space: pre;
  text-shadow: 0 0.1px 0 #000;
  border-radius: 5px;
  box-shadow: 1px 1px 5px -1px rgb(161, 161, 161);
  font: 16px/24px "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;

  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.btn-toggle-dark {
  position: absolute !important;
  right: 16px !important;
  margin-top: 4px !important;
  margin-right: 10px !important;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.steps-content {
  margin-top: 16px;
  min-height: 200px;
  /* text-align: center; */
  /* padding-top: 80px; */
}

.steps-action {
  margin-top: 24px;
}

.ant-tabs-bar {
  margin: 0 !important;
}

.charger-count-dropdown {
  text-align: center;
  cursor: default !important;
}

.ant-notification {
  max-width: initial;
}


.ant-notification.ant-notification-bottomLeft {
  color: #fff;
  width: 100%;
  margin-left: 0;
  margin-bottom: 0;
  bottom: 0 !important;

  .ant-notification-notice-message {
    color: #fff;
  }

  .ant-notification-notice-btn {
    float: none;
  }
}

#login-image {
  width: 300px;

  @media screen and (min-width: 550px) {
    width: 500px;
  }
}

#lifec_logo_header {
  width: 200px;
  margin-left: -14px;
}

#root {
  background-color: var(--fog);
  color: var(--dark-kelp);
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-Book.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-BookItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-SemiBolditalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Montreal";
  src: url("../fonts/PPNeueMontreal-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

body {
  font-family: "Montreal" !important;
}

.bg-fog {
  background-color: var(--fog);
}

.bg-bright-fog {
  background-color: var(--bright-fog);
}

.bg-dark-fog {
  background-color: var(--dark-fog);
}

/* styling for login form wrapper  */
.ant-form-item label {
  color: var(--dark-kelp) !important;
}

.ant-tabs-nav {
  color: var(--dark-kelp) !important;

  .ant-tabs-tab:hover {
    color: var(--kelp) !important;
  }

  .ant-tabs-tab-active {
    color: var(--kelp) !important;
  }

  .ant-tabs-ink-bar {
    background-color: var(--kelp) !important;
  }
}

.ant-btn:not(.ant-btn-link):not(.ant-btn-danger):not(.ant-btn-primary) {
  background-color: var(--bright-fog) !important;
  border-color: var(--dark-fog) !important;
  color: var(--dark-fog) !important;

  &:hover {
    border-color: var(--kelp) !important;
    color: var(--kelp) !important;
  }
}

.ant-btn-link,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: var(--kelp) !important;

  &:hover {
    color: var(--dark-kelp) !important;
  }
}

.ant-btn:not(.ant-btn-link).ant-btn-primary {
  background-color: var(--dark-fog) !important;
  border-color: var(--dark-fog) !important;
  color: var(--bright-fog) !important;

  &:hover {
    background-color: var(--bright-fog) !important;
    color: var(--dark-fog) !important;
  }
}

.ant-table-content {
  background: white !important;
}

.ant-select-search__field__wrap {
  background-color: var(--bright-fog) !important;

  .ant-select-search__field {
    background-color: white !important;
  }
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--dark-fog) !important;
  color: var(--bright-fog) !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: var(--kelp) !important;

  span,
  i {
    color: var(--fog) !important;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: var(--bright-magic) !important;
  color: var(--bright-fog) !important;
}

// input border and outline
.ant-select-selection:hover,
.ant-select-selection:focus,
.ant-input:focus,
.ant-input:hover,
.ant-checkbox:hover {
  border-color: var(--kelp) !important;
  box-shadow: none !important;
}

// checkbox
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--kelp) !important;
  border-color: var(--kelp) !important;
}

// checkbox hover
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--kelp) !important;
}

.ant-checkbox-checked::after {
  border-color: var(--kelp) !important;
}

// table row hover
.ant-table-row:hover td,
.ant-table-row:hover th {
  background-color: var(--bright-magic) !important;
}

.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background-color: var(--bright-magic) !important;
}

// pagination table
.ant-pagination-item-active {
  border-color: var(--kelp) !important;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-prev:hover,
.ant-pagination-next:hover,
.ant-pagination-prev:focus,
.ant-pagination-next:focus {
  border-color: var(--kelp) !important;

  a {
    color: var(--kelp) !important;
  }
}

.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: var(--kelp) !important;
}

.ant-spin-dot-item {
  background-color: var(--kelp) !important;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: var(--kelp) !important;
}

.ant-tag-checkable:active {
  background-color: var(--bright-fog) !important;
}

// default chrome auto fill styling
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// hover and drag on upload
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover,
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: var(--kelp) !important;
}

// custom message.success icon background color
.ant-message-success .anticon {
  color: var(--dark-magic) !important;
}

// steps
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--dark-magic) !important;
  border-color: var(--dark-magic) !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon, 
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--kelp) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--dark-magic) !important;
}

.ant-steps-icon {
  color: var(--dark-magic) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--dark-magic) !important;
}

// checkable tag
.ant-tag-checkable-checked {
  background-color: var(--dark-magic) !important;
  color: var(--bright-fog) !important;
}

// progress
.ant-progress-success-bg, .ant-progress-bg {
  background-color: var(--bright-kelp) !important;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: var(--dark-magic) !important;
}

.ant-progress-status-success .ant-progress-text {
  color: var(--dark-magic) !important;
}

.ant-menu-item-selected {
  color: var(--fog) !important;
}